import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from "gatsby-image";
import Layout from "../components/layout"
import AnjosForm from "../components/anjosForm"
import {
  Section,
  Column,
  Columns,
  Content
} from "bloomer"  
import SEO from '../components/seo';


export default ({ data, pageContext }) => {
  // const { currentPage, numPages } = pageContext;
  return (
    <Layout>
      <SEO title="Os Anjos - Estrelaguia Guia"></SEO>
        <Content>
          <h1>Os Anjos</h1>
          <h2>Seu Anjo Protetor</h2>
          <p>O Anjo é uma criatura celestial, um ser intermediário entre Deus e os homens. Tem a função de ajudante ou mensageiro de Deus. Aparecem mais de 300 vezes na Bíblia, usando nomes como Santos, heróis e filhos de Deus. Os anjos são enviados divinos que protegem e guiam os seres humanos.</p>
          <p>Eles são próximos aos homens, presentes nas tradições de diversas civilizações (gregos, romanos, . São feitos de luz e podem estar em vários lugares ao mesmo tempo e podem assumir formas humanas. Acredita-se que se comunicam conosco mandando vibrações e por meio dos nossos sonhos.</p>
          <p>O seu anjo da guarda te acompanha, é o seu guardião. Mas não interferem no seu livre arbítrio. É você quem toma as suas decisões e é o responsável por estas.</p>
          <p>Invocá-los com o bem em mente pode contribuir para que a pessoa tenha um interesse mais profundo pelo próximo. Pode ajudá-la a ter luz espiritual por meio do aperfeiçoamento de seus atos e pensamentos. A ser uma pessoa com propósitos positivos.</p>
          <h2>Descubra seu Anjo Protetor</h2>
          <AnjosForm></AnjosForm>
        </Content>
        <Columns isMultiline className="column">
          {data.allMarkdownRemark.edges.map(({ node }) => (
             <Column key={node.id} isSize="1/2">   
              <Link to={node.fields.slug} className="media"> 
              <div className="media-left">      
                <Img
                  className="image is-128x128"
                  fluid={node.frontmatter.image.childImageSharp.fluid} />
                  </div>   
                <Content className="media-content">
                  <h3>{node.frontmatter.name}</h3>
                  <p> {node.excerpt}</p>
                </Content>
              </Link> 
             </Column>
          ))}
          </Columns>
    </Layout> 
  );
};

export const query = graphql`
  query anjosListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "anjos" } }
      }
      sort: { fields: [frontmatter___angel_id], order: ASC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            name
            image {
              childImageSharp {
                fluid(
                  maxWidth: 200,
                  maxHeight: 200,
                  fit:COVER,
                  cropFocus:NORTH,
                    duotone: {
                      highlight: "#FFFFFF",
                      shadow: "#000000",
                      opacity: 80
                    },
                    background:"rgba(255,255,255,1)",
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt(pruneLength: 80)
        }
      }
    }
  }
`;